body {
  font-size: 1.25em; /* 全体のフォントサイズを大きくする */
  background-color: #f9f9f9; /* 背景色を設定 */
}

.image-viewer-container {
  padding: 20px;
  font-size: 1.25em; /* コンテナ全体のフォントサイズを大きくする */
}

.image-viewer-header {
  display: flex;
  justify-content: center; /* space-between */
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

.image-viewer-header .title-container {
  display: flex;
  align-items: center;
}

.image-viewer-header img {
  width: 40px; /* アイコンのサイズを大きくする */
  height: 40px;
  margin-right: 15px;
}

.image-viewer-header h1 {
  font-size: 1.5em; /* タイトルのフォントサイズを大きくする */
  margin: 0;
}

.image-viewer-header button {
  position: absolute;
  right: 0;
  font-size: 1.25em; /* ボタンのフォントサイズを大きくする */
  display: flex;
  align-items: center;
  padding: 8px 16px;
  appearance: none; /* ブラウザのデフォルトスタイルを無効にする */
  -webkit-appearance: none; /* Safariのデフォルトスタイルを無効にする */
  background-color: #f0f0f0; /* ボタンの背景色 */
  border: 1px solid #ccc; /* ボタンの枠線 */}

.image-viewer-header button img {
  width: 24px; /* ボタン内のアイコンのサイズを大きくする */
  height: 24px;
  margin-right: 8px;
}

.image-viewer-date-time {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 1.25em; /* 日付部分のフォントサイズを大きくする */
  margin-bottom: 20px;
}

.image-viewer-date-time img {
  width: 40px; /* カレンダーアイコンのサイズを大きくする */
  height: 40px;
  margin-right: 15px;
}

.image-viewer-date-time p {
  margin: 0;
  font-weight: bold;
  font-size: 1.25em; /* 日付のフォントサイズ */
  color: #343a40; /* 濃いグレー */
}

.image-viewer-date-time button {
  font-size: 1.0em; /* ボタンのフォントサイズを大きくする */
  display: flex;
  margin-left: 8px;
  align-items: center;
  padding: 8px 16px;
  appearance: none; /* ブラウザのデフォルトスタイルを無効にする */
  -webkit-appearance: none; /* Safariのデフォルトスタイルを無効にする */
  background-color: #f0f0f0; /* ボタンの背景色 */
  border: 1px solid #ccc; /* ボタンの枠線 */
}

.image-viewer-container-number {
  font-size: 1.7em; /* コンテナ番号のフォントサイズを調整 */
  font-weight: bold;
  margin-bottom: 20px;
}

.image-viewer-damage {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  white-space: nowrap; /* 改行を無効にする */
}

.image-viewer-damage-label {
  font-size: 1.25em;
  background-color: #e9ad4d; /* ライトイエロー */
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  margin-right: 10px;
  white-space: nowrap; /* 改行を無効にする */
}

.image-viewer-damage-value {
  font-size: 1.25em;
  background-color: white;
  color: #e9ad4d; /* ライトイエロー */
  padding: 5px 10px;
  border-radius: 4px;
}

.CDirectionCtrl{
  position: relative;
  width: 100%;
  margin-bottom:80px;
}
.ToggleBtnDirection{
  position: absolute;
  left: 0; /* ボタンをウィンドウの左端に配置 */
  font-size: 1.25em;
  appearance: none; /* ブラウザのデフォルトスタイルを無効にする */
  -webkit-appearance: none; /* Safariのデフォルトスタイルを無効にする */
  background-color: #f0f0f0; /* ボタンの背景色 */
  border: 1px solid #ccc; /* ボタンの枠線 */
}

.arrow-container {
  position: absolute;
  left: 50%; /* 左端から50% */
  transform: translateX(-50%); /* 中央に配置 */
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
}

.arrow {
  color: #333; /* 矢印の色 */
}

.image-viewer-thumbnails {
  display: grid;
  /* grid-template-columns: 1fr;4x1 Only */
  /* grid-template-columns: repeat(2, 1fr); /* 2x2表示*/
  gap: 10px;
}
/* 最初の4つは1列 */
.image-viewer-thumbnails img:nth-child(-n+4) {
  grid-column: span 2; /* 1列全体を使うように設定 */
}
/* 2024.08.09 OCR用画像の追加　ポップアップのスタイル */
.image-viewer-thumbnails img:nth-child(5),
.image-viewer-thumbnails img:nth-child(6) {
  grid-column: span 1; /* 5行目だけ2列にする */
  max-height: 200px; /* 必要に応じて高さを調整 */
}
.image-viewer-thumbnails img {
  width: 100%;
  height: auto;
  max-height: 200px; /* 4x1 Only 例として200pxに制限 */
  object-fit: contain; /* 4x1 Only? アスペクト比を保持 */
  border: 1px solid #ced4da;
  border-radius: 4px;
  cursor: pointer;
}
/* 全体の列数は2列 */
.image-viewer-thumbnails {
  grid-template-columns: 1fr 1fr;
}

.topflip-ctrl{
  margin-top:20px;
  margin-bottom: 80px;
}

.topflip-ctrl .toggle-flip-button {
  position: absolute;
  left: 40;
  font-size: 1.0em; /* ボタンのフォントサイズを大きくする */
  display: flex;
  align-items: center;
  padding: 8px 16px;
  appearance: none; /* ブラウザのデフォルトスタイルを無効にする */
  -webkit-appearance: none; /* Safariのデフォルトスタイルを無効にする */
  background-color: #f0f0f0; /* ボタンの背景色 */
  border: 1px solid #ccc; /* ボタンの枠線 */
}
.flipped-image {
  transform: rotate(180deg); /* 180°回転 */
}
.flipped-image-left {
  transform: scaleX(-1);
}

/* ポップアップのスタイル */
.image-viewer-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.image-viewer-popup img {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* アスペクト比を保持 */
}

.image-viewer-popup .close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 96px; /* クローズボタンのサイズを大きくする */
  color: white;
  cursor: pointer;
  touch-action: pan-x pan-y;
}

.image-viewer-popup .container-number {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 48px;
  font-weight: bold;
  color: white;
  white-space: nowrap; /* 改行を無効にする */
  touch-action: pan-x pan-y;
}

.image-viewer-damage-details {
  margin-top: 20px;
  font-size: 1.0em;
  color: #343a40; /* 濃いグレー */
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 4px;
  white-space: nowrap; /* 折り返さずに表示 */
  overflow: visible; /* 画面外に表示が続く */
}

.image-viewer-damage-details p {
  font-weight: normal; /* 太字を解除 */
  margin: 5px 0; /* 各行に少しスペース */
}
