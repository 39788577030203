.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f9f9f9; /* 背景色を指定 */
}

.login-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-header img {
  width: 40px; /* アイコンのサイズを大きくする */
  height: 40px;
  margin-right: 15px;
}

.login-header h1 {
  font-size: 1.5em; /* タイトルのフォントサイズを大きくする */
  margin: 0;
}

.login-form {
  margin-top: 20px;
}

.login-form p {
  font-size: 1.25em; /* フォームの説明テキストのフォントサイズを大きくする */
}

.login-form input {
  font-size: 1.25em; /* 入力フィールドのフォントサイズを大きくする */
  padding: 8px;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
}

.login-form button {
  font-size: 1.25em; /* ボタンのフォントサイズを大きくする */
  padding: 10px 20px;
}
