body {
  font-size: 1.25em; /* 全体のフォントサイズを大きくする */
  background-color: #f9f9f9; /* 背景色を設定 */
}

.history-container {
  padding: 20px;
  font-size: 1.25em; /* コンテナ全体のフォントサイズを大きくする */
}

.history-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

.history-header .title-container {
  display: flex;
  align-items: center;
}

.history-header .title-container img {
  width: 40px; /* アイコンのサイズを大きくする */
  height: 40px;
  margin-right: 15px;
}

.history-header .title-container h1 {
  font-size: 1.5em; /* タイトルのフォントサイズを大きくする */
  margin: 0;
}

.reload-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}
.reload-list .reload-button {
  position: absolute;
  left: 0px;
  font-size: 1.00em; /* ボタンのフォントサイズを大きくする */
  display: flex;
  align-items: center;
  padding: 8px 16px;
  appearance: none; /* ブラウザのデフォルトスタイルを無効にする */
  -webkit-appearance: none; /* Safariのデフォルトスタイルを無効にする */
  background-color: #f0f0f0; /* ボタンの背景色 */
  border: 1px solid #ccc; /* ボタンの枠線 */
}

.reload-list .reload-button img {
  width: 24px; /* ボタン内のアイコンのサイズを大きくする */
  height: 24px;
  margin-right: 8px;
}

.history-header .latest-button {
  position: absolute;
  right: 0;
  font-size: 1.25em; /* ボタンのフォントサイズを大きくする */
  display: flex;
  align-items: center;
  padding: 8px 16px;
  appearance: none; /* ブラウザのデフォルトスタイルを無効にする */
  -webkit-appearance: none; /* Safariのデフォルトスタイルを無効にする */
  background-color: #f0f0f0; /* ボタンの背景色 */
  border: 1px solid #ccc; /* ボタンの枠線 */
}

.history-header .latest-button img {
  width: 24px; /* ボタン内のアイコンのサイズを大きくする */
  height: 24px;
  margin-right: 8px;
}

.history-date {
  display: flex;
  align-items: center;
  font-size: 1.25em; /* 日付部分のフォントサイズを大きくする */
  margin-bottom: 20px;
}

.history-date img {
  width: 40px; /* カレンダーアイコンのサイズを大きくする */
  height: 40px;
  margin-right: 15px;
}

.history-date p {
  font-size: 1.25em; /* 日付のフォントサイズを大きくする */
  margin: 0;
  font-weight: bold;
  margin-right: 16px;
  color: #343a40; /* 濃いグレー */
}

.history-date button {
  font-size: 1.25em; /* 日付変更ボタンのフォントサイズを大きくする */
  padding: 8px 16px;
  appearance: none; /* ブラウザのデフォルトスタイルを無効にする */
  -webkit-appearance: none; /* Safariのデフォルトスタイルを無効にする */
  background-color: #f0f0f0; /* ボタンの背景色 */
  border: 1px solid #ccc; /* ボタンの枠線 */
}

.dropdown-overlay {
  position: absolute;
  z-index: 1000;
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  font-size: 1.5em; /* ドロップダウンのフォントサイズを大きくする */
}

.uk-nav.uk-dropdown-nav {
  list-style: none;
  padding: 0;
  margin: 0;
}

.uk-nav.uk-dropdown-nav li {
  padding: 8px 16px;
}

.uk-nav.uk-dropdown-nav li.uk-active {
  font-weight: bold;
}

.uk-nav.uk-dropdown-nav li a {
  text-decoration: none;
  color: black;
}

.uk-nav.uk-dropdown-nav li a:hover {
  background-color: #f0f0f0;
}

.history-table {
  width: 100%;
  border-collapse: collapse;
  
}

.history-table th, .history-table td {
  border-bottom: 2px solid #ccc;
  padding: 30px 10px;
  font-family:monospace;
  font-size: 1.0em; /* テーブルのフォントサイズを大きくする */
}

.history-table th {
  background-color: #f8f8f8;
  font-family:monospace;
  font-weight: bold;
}

.history-table td {
  cursor: pointer;
  white-space: nowrap; /* 改行を防ぐ */
}

.history-table td:hover {
  background-color: #f0f0f0;
}
